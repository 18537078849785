<template>
<div>
  <h1>Studio</h1>
  <video
    v-if="final"
    :srcObject.prop="final"
    autoplay playsinline
  /><br/>
  <webrtc-2 
    v-if="room"
    :user="{id:'studio-01', name:'Studio'}"
    :userConfig="{'noUserMedia':true, 'startInvisible':true}"
    :room="room"
    @usersChange="webRTCUsersChanged"
  />
  <userlist
    ref="userlist"
    :ui-config="{'showInvisible':true, 'showStream':true}"
  />
  <div>
    RoomId: <input type="text" v-model="roomId"/>
    <button class="btn btn-primary" @click="joinRoom">Join</button>
  </div>
  <div>
    <button class="btn btn-primary" @click="compose">Compose</button>
    <button class="btn btn-primary" @click="deleteComposer">Delete Composer</button>
  </div>
  <div>
    <button class="btn btn-primary" @click="record" v-if="!isRecording">Record</button>
    <button class="btn btn-primary" @click="recordStop" v-else>Stop and Save</button>
  </div>
  <div>
    <button class="btn btn-primary" @click="startLiveStream">Start Live Stream</button>
  </div>
</div>
</template>

<script>
import { db } from "@/services/db";
import { getLog } from "@/services/log";
let log = getLog("studio");
import Webrtc2 from '@/components/webrtc2.vue';
import userlist from '@/components/userlist.vue';
import { VideoStreamMerger } from "@/components/video-stream-merger";
import RecordRTC from 'recordrtc';
import { getBrowser } from '@/services/utils';
import { saveAs } from 'file-saver';

export default {
  components: {
    Webrtc2,
    userlist,
  },
  data() {
    return {
      roomId: "tOeLviulNXEnmjrOMvqr",
      room: null,
      final: null,

      isRecording: false,
      users: [],

      composite: {
        dest: {
          width: 540,
          height: 860,
          fps: 30,
          clearRect: true
        },
        streams: [{
          x: 0, // position of the top-left corner
          y: 0,
          width: 540,     // size to draw the stream
          height: 430,
          index: 0, // Layer on which to draw the stream (0 is bottom, 1 is above that, and so on)
          mute: false,  // if true, any audio tracks will not be merged
          draw: null,    // A custom drawing function (see below)
          audioEffect: null // A custom WebAudio effect (see below)
        }, {
          x: 0, // position of the top-left corner
          y: 430,
          width: 540,     // size to draw the stream
          height: 430,
          index: 0, // Layer on which to draw the stream (0 is bottom, 1 is above that, and so on)
          mute: false,  // if true, any audio tracks will not be merged
          draw: null,    // A custom drawing function (see below)
          audioEffect: null // A custom WebAudio effect (see below)
          }
        ],
        watermark: {
          id: "watermark_wwf",
          img: require("@/assets/wwf.png"),
          alignment: "br",
          width: 140,
          height: 188
        },
        watermark2: {
          id: "watermark_tml",
          img: require("@/assets/tml.png"),
          alignment: "bl",
          width: 160,
          height: 174
        }
      },
      streaming: {
        // YTL
        url: "rtmp://a.rtmp.youtube.com/live2/sh38-y1j3-vya6-34kh-7jra",
        // FBL
        //url: "rtmps://live-api-s.facebook.com:443/rtmp/10158941749512836?s_bl=1&s_ps=1&s_psm=1&s_sw=0&s_vt=api-s&a=AbwZIKoUt--SSI6H",
      }
    }
  },
  mounted() {
    document.title = "Studio";
    this.$debug.isOn = true;
  },
  methods: {
    async webRTCUsersChanged(users) {
      this.users = users;
      this.$refs.userlist.updateUsers(users);
      log.log("webRTCUsersChanged", users);
    },
    joinRoom() {
      this.$bind("room", db.collection("LiveRooms").doc(this.roomId));
    },
    compose() {
      log.log("Stream[0]:", this.users[0].stream, this.getStreamSettings(this.users[0].stream));
      log.log("Stream[1]:", this.users[1].stream, this.getStreamSettings(this.users[1].stream));
      if (!this.users[0].stream || !this.users[1].stream) {
        log.error("missing stream");
        return;
      }
      let merger = new VideoStreamMerger(this.composite.dest);
      // main streams
      this.addStream(merger, this.users[0].stream, this.composite.streams[0]);
      this.addStream(merger, this.users[1].stream, this.composite.streams[1]);
      // watermark
      this.addWatermark(merger, this.composite.watermark);
      this.addWatermark(merger, this.composite.watermark2);
      merger.start();
      this.final = merger.result;
      this.merger = merger;
    },
    deleteComposer() {
      this.merger.destroy();
      delete this.merger;
      this.final = null;
    },
    computeSourceRect(stream, destRect) {
      let srcInfo = this.getStreamSettings(stream);
      let destRatio = destRect.width / destRect.height;
      if (destRatio > srcInfo.aspectRatio) {
        let rh = srcInfo.width / destRatio;
        return {x:0, width:srcInfo.width, y:(srcInfo.height - rh)/2, height:rh};
      } else {
        let rw = srcInfo.height * destRatio;
        return {x:(srcInfo.width - rw)/2, width:rw, y:0, height:srcInfo.height};
      }
    },
    addStream(merger, stream, streamParams) {
      let s = this.computeSourceRect(stream, streamParams);
      log.log("Stream src:", s);
      let d = streamParams;
      merger.addStream(stream, Object.assign(streamParams, {
        draw: (ctx, frame, done) => {
          ctx.drawImage(frame, s.x, s.y, s.width, s.height, d.x, d.y, d.width, d.height);
          done();
        }
      }));
    },
    addWatermark(merger, wm) {
      let screen = this.composite.dest;
      let image = new Image(wm.width, wm.height);
      let rect = {x: 0, y: 0, width:wm.width, height:wm.height};
      if (wm.alignment == "br")
        Object.assign(rect, {
          x: screen.width - wm.width,
          y: screen.height - wm.height
        });
      else if (wm.alignment == "bl")
        Object.assign(rect, {
          y: screen.height - wm.height
        });
      image.src = wm.img;
      merger.addStream(wm.id, {
        draw: (ctx, frame, done) => {
          ctx.drawImage(image, rect.x, rect.y, rect.width, rect.height);
          done();
      }});
    },
    record() {
      this.isRecording = true;
      this.recorder = this.createRecorder(this.final);
    },
    getStreamSettings(stream) {
      return stream.getVideoTracks()[0].getSettings();
    },
    createRecorder(stream) {
      // options: https://www.npmjs.com/package/recordrtc
      var res = null;
      var options = {};
      if (getBrowser() == "safari") {
        log.error("Recording not supported in safari");
      } else {
        options = {
          type: 'video',
          mimeType: 'video/webm;codecs=vp9',
        };
        log.log("Create recorder options=", options);
        res = new RecordRTC(stream, options);
      }
      res.startRecording();
      return res;
    },
    async recordStop() {
      this.recorder.stopRecording(() => {
        let blob = this.recorder.getBlob();
        saveAs(blob);
        this.recorder.destroy();
        this.recorder = null;
        this.isRecording = false;
      });
    },
    startLiveStream() {
      let wsUrl = window.location.protocol.replace('http', 'ws') + '//' + // http: => ws:, https: -> wss:
        //window.location.host +
        "localhost:3000" +
        '/rtmp/' +
        encodeURIComponent(this.streaming.url);
      log.log("wsUrl", wsUrl);
      const ws = new WebSocket(wsUrl);

      ws.addEventListener('open', (e) => {
        log.log('WebSocket Open', e);
        let mediaStream = this.final //document.querySelector('canvas').captureStream(30); // 30 FPS
        this.mediaRecorder = new MediaRecorder(mediaStream, {
          mimeType: 'video/webm;codecs=h264',
          videoBitsPerSecond : 3000000
        });

        this.mediaRecorder.addEventListener('dataavailable', (e) => {
          ws.send(e.data);
        });

        this.mediaRecorder.addEventListener('stop', ws.close.bind(ws));

        this.mediaRecorder.start(1000); // Start recording, and dump data every second
      });

      ws.addEventListener('close', (e) => {
        log.log('WebSocket Close', e);
        this.mediaRecorder.stop();
      });
    }
  }
}
</script>

<style>

</style>